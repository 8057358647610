<template>
  <div id="events">
    <div class="container-fluid">
        <div class="row h-100 overflow-hidden">
            <div class="slider-inner col-12 ms-0 ms-sm-5">
                <div class="slider-content ms-0 ms-sm-5">
                    <div class="marque">NOPROBLEMO x FAMILY DRUGSTORE</div>
                    <h2>CRÉATEUR <br class="d-none d-sm-block" />D'ÉVÉNEMENTS</h2>
                    <i class="categorie playfairdisplay">Events • Street Art • Digital</i>
                </div>
            </div>
        </div>

      <div id="slide-mini-video" v-on:click="lien('video', 'fd-film-promo_v2_030919')">
            <div id="slide-mini-video-title" class="col-7">Découvrez le film</div>
            <div class="slide-video-ico col-5">
                <span></span>
                <img src="../assets/images/icon/Icn_play.svg"/>
            </div>
        </div>
    </div>
    
    <div class="bg-white text-dark">
      <div class="container pt-5 pb-5">
          <div class="row pt-5 pb-5 text-center">
              <div class="col-12">
                  <h2 class="playfairdisplay pb-5">Noproblemo X Family Drugstore</h2>
                  <p>D’un côté, le savoir-faire de Family Drugstore, son réseau d’artistes variés, son adaptabilité à la demande.<br/>De l’autre, l’efficacité de Noproblemo, son sens du packaging à 360, du tournage à la création de contenus multicanaux.</p>
                  <p>Noproblemo et Family Drugstore s’associent pour vous proposer des events sur mesure.</p>
                  <p>Pour plus d’informations, contactez-nous par mail.</p>
                  <a v-bind:href="'mailto:events@noproblemo.eu'" class="bt-hover">CONTACT US</a>
              </div>
          </div>
      </div>
    </div>

    <div class="vignette container pt-5 pb-5">
      <div class="row">
        <div class="col-12 col-md-6 playfairdisplay text-32">
            Nos derniers événements
        </div>
        <div class="col-12 mt-3">
            <div class="row mt-5 align-items-center container-inverse-child" v-for="task in fd" :key="task.titre">
                <div class="col-12 col-md-8">
                    <div class="image-hover img-inner-shadow">
                        <img class="ratio" :src="task.image" :alt="task.titre">
                        <div class="layer"></div>
                    </div>
                </div>
                <div class="col-12 mt-5 mt-md-0 col-md-4 text-center">
                    <img class="img-fluid" :src="task.marque" alt="">
                    <h3 class="mt-5 mb-5 pt-lg-4 pb-lg-4" v-html="task.titre">{{ task.titre }}</h3>
                    <button class="bt-hover-bk m-md-0 w-md-100 ps-md-4 pe-md-4 ps-lg-5 pe-lg-5" v-on:click="lien(task.video, task.lien)">{{ task.button }}</button>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: 'works',
        data() {
            return {
                fd: [
                    {
                      image: require('../assets/images/fd/Mosaique_event_gnr.jpg'),
                      marque: require('../assets/images/fd//logo/logo_universal.png'),
                      titre: 'Pop-Up Store <br class="d-none d-md-block" />Guns N’ Roses',
                      button:'Voir l’event',
                      video: '',
                      lien: 'https://familydrugstore.fr/events/pop-up-store/'
                    },
                    {
                      image: require('../assets/images/fd/Mosaique_events_gentside.jpg'),
                      marque: require('../assets/images/fd//logo/logo-gts-black.png'),
                      titre: 'Birdy Wall',
                      button:'Voir l’event',
                      video: '',
                      lien: 'https://familydrugstore.fr/events/birdy-wall/'
                    },
                    {
                        image: require('../assets/images/fd/Mosaique_event_sisley.jpg'),
                        marque: require('../assets/images/fd//logo/logo-sisley.png'),
                        titre: 'Custom Denim Party',
                        button:'Voir l’event',
                        video: '',
                        lien: 'https://familydrugstore.fr/events/custom-denim/'
                    },
                    {
                      image: require('../assets/images/fd/Mosaique_event_huffpost.jpg'),
                      marque: require('../assets/images/fd//logo/logo-huffpost.png'),
                      titre: 'Battle Street Art<br /><span class="small-titre">Bea Toa vs Jo Di Bona</span>',
                      button:'Voir l’event',
                      video: '',
                      lien: 'https://familydrugstore.fr/events/dessine-moi-un-wall/'
                    },
                    {
                      image: require('../assets/images/fd/Mosaique_event_freia.jpg'),
                      marque: require('../assets/images/fd//logo/logos-unipoly.png'),
                      titre: 'Promo Artiste Freia<br /><span class="small-titre">Création Visuel EP & Vidéo Promo RS</span>',
                      button:'Voir l’event',
                      video: '',
                      lien: 'https://familydrugstore.fr/events/draw-my-life/'
                    },
                    {
                      image: require('../assets/images/fd/Mosaique_event_urbn.jpg'),
                      marque: require('../assets/images/fd//logo/logo-urbn.png'),
                      titre: 'Fresques Université Paris Dauphine',
                      button:'Voir l’event',
                      video: '',
                      lien: 'https://familydrugstore.fr/events/urbaine-dauphine/'
                    },
                ]
            }
        },
        methods: {
            greetVideo: function () {
                this.$root.$children[0].linkPlayer('rungis');
            },
            lien: function (event1, event2) {
                if(event1 !== ''){
                    this.$root.$children[0].linkPlayer(event2);
                } else {
                    console.log(event2);
                    window.open(event2, '_blank');
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
/* inverse div */
@media screen and (min-width: 768px) {
    .container-inverse-child:nth-child(2n+2){
        div:first-child{
            order: 2;
        }
        div:last-child{
            order: 1;
        }
    }
}

/* button */
.bt-hover-bk{
    position: relative;
    background: none;
    padding: 25px 80px;
    display: inline-block;
    margin: 15px 30px;
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 16px;
    outline: none;
    border: 1px solid #fff;
    color: #fff;
    transition: all 0.3s;

    &:hover{
        background: #fff;
        color: #131314;
    }
}

.container-fluid{
  position: relative;
  height: 100vh;
  background-color: black;
  /*background-image: url("../assets/images/fd/familydrugstore.jpg");*/
  background-image: url("../assets/images/fd/familydrugstore2.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

    @include media-breakpoint-down(sm){
        background-image: url("../assets/images/fd/familydrugstore-m.jpg");
    }

  .slider-inner {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 1546px;
        height: 100%;
        padding: 74px 0 150px 0;
        margin: 0 auto;
        z-index: 5;
    }

    .slider-content {
        padding: 0 10px;


        .marque{
            opacity: 0.5;

            &:before{
                content:'';
                display:inline-block;
                position: relative;
                vertical-align: middle;
                bottom: 2px;
                width: 2rem;
                height: 1px;
                background: white;
                margin-right: 0.4rem;
            }
        }

        h2 {
            font-weight: 400;
            font-size: 2.5rem;
            color: white;
            line-height: 40px;

            @media screen and (max-width: 800px) {
                font-size: 2rem;
                line-height: 2rem;
            }
        }

        .categorie {
            display: block;
            font-size: 28px;
            color: white;
        }
    }
}

/* Our latest events */
  .text-32{
    font-size: 32px;
  }

  /* vignettes */
  .vignette{
      
    .img-inner-shadow {
            position: relative;
            .layer{
                position:absolute;
                top:0px;
                right:0px;
                bottom:0px;
                left:0px;
                transition:all 0.6s ease-in-out;
            }
        }

        h3{
            cursor: pointer;
            font-size: 30px;
            font-weight: bold;
        }

        i.playfairdisplay{
            font-size: 14px;
        }
  }


    // Player
    #slide-mini-video{
        position: absolute;
        width: 374px;
        height: 150px;
        bottom: 0;
        right: 0;
        color: #131314;
        background-color: white;
        margin-bottom: 50px;
        cursor: pointer;
        z-index: 6;

        @media screen and (max-width: 420px) {
            width: 100%;
        }

        @media screen and (max-width: 575px) {
            margin-bottom: 0px;
        }

        span{
            display: none;
        }

        #slide-mini-video-title{
            display: inline-block;
            /*width: 224px;*/
            height: 150px;
            line-height: 150px;
            text-align: center;
        }

        .slide-video-ico{
            position: absolute;
            top: 0;
            right: 0;
            /*width: 150px;*/
            height: 150px;
            text-align: center;
            background-color: #B32572;
            overflow: hidden;
            transition: background-color 0.5s;

            span {
                display: inline-block;
                height: 100%;
                vertical-align: middle;
            }

            img{
                vertical-align: middle;
                max-width: 60px;
            }

            video{
                display: inline-block;
                height: 100%;
                vertical-align: middle;

                position: absolute;
                opacity: 0;
                top: 0;
                right: 0;
                transition: opacity 0.5s, visibility 0s 0.5s;
            }
        }
    }

    /* button */
    .bt-hover{
        position: relative;
        background: #B32572;
        padding: 25px 80px;
        display: inline-block;
        margin: 15px 30px;
        letter-spacing: 1px;
        font-weight: 500;
        font-size: 16px;
        outline: none;
        text-decoration: none;
        border: 1px solid #fff;
        color: #fff;
        transition: all 0.3s;

        &:hover{
            border-color: #B32572;
            background: #fff;
            color: #B32572;
        }
    }
</style>
